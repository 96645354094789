<template>
  <LoadingContainer
    :is-loading="isLoadingPost || isLoadingPostCategories || isLoadingTags"
  >
    <form class="has-sticky-submit" @submit.prevent="submit">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">
            <div class="title is-6 is-marginless">
              Άρθρα <span class="tag">{{ general.title }}</span>
            </div>
          </div>
          <div class="card-header-icon">
            <router-link :to="{ name: 'content.posts.list' }" class="button">
              <span class="icon is-small"><i class="fa fa-list"/></span
              ><span>Λίστα</span>
            </router-link>
          </div>
        </div>
        <div class="card-content">
          <EditGeneralDetails :general="general" @updateGeneral="updateGeneral">
            <div class="column">
              <label class="label">Ημερομηνία Δημοσίευσης</label>
              <div class="field">
                <div class="control is-expanded">
                  <Datepicker
                    v-model="general.published_at"
                    :typeable="true"
                    name="published_at"
                    format="dd-MM-yyyy"
                    language="el"
                    wrapper-class="field"
                    input-class="input"
                    placeholder="πχ: 02-12-2017"
                  />
                </div>
              </div>
            </div>
          </EditGeneralDetails>
        </div>
      </div>

      <edit-photos
        :model="model"
        :photos="photos"
        :is-loading="isLoadingPhotos"
        @updatePhotos="updatePhotos"
      />

      <ToggleableCard :is-open="true" title="Author">
        <div class="card-content">
          <div class="field">
            <authors-search
              :author="general.author"
              @selectAuthor="selectAuthor"
              @removeAuthor="removeAuthor"
            />
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Κατηγορίες - Συμπτώματα - Tags">
        <div class="card-content">
          <div class="columns is-multiline">
            <div class="column is-full">
              <div class="field">
                <label class="label">Κατηγορίες</label>
                <div class="control">
                  <multiselect
                    v-model="general.post_categories"
                    :options="postCategories"
                    :multiple="true"
                    name="post_categories"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε κατηγορίες"
                  />
                </div>
              </div>
            </div>

            <div class="column is-full">
              <label class="label">Συμπτώματα</label>
              <symptoms-search
                :symptoms="general.symptoms"
                @selectSymptom="selectSymptom"
                @removeSymptom="removeSymptom"
              />
            </div>

            <div class="column is-full">
              <div class="field">
                <label class="label">Tags</label>
                <div class="control">
                  <multiselect
                    v-model="general.tags"
                    :options="tags"
                    :multiple="true"
                    name="tags"
                    class="multiple"
                    track-by="id"
                    label="title"
                    placeholder="Επιλέξτε tags"
                    selected-label="Επιλεγμένο"
                    select-label="Πατήστε enter για επιλογή"
                    deselect-label="Πατήστε enter για απο-επιλογή"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Σχετικά Προϊόντα">
        <div class="card-content">
          <div class="field">
            <label class="label">Σχετικά Προιόντα</label>
            <div class="control">
              <products-search
                :products="general.products"
                @selectProduct="selectProduct"
                @removeProduct="removeProduct"
              />
            </div>
          </div>
        </div>
      </ToggleableCard>

      <ToggleableCard :is-open="true" title="Ανακατεύθυνση (301)">
        <div class="card-content">
          <EditRedirectionDetails
            :redirection="redirection"
            @updateRedirection="updateRedirection"
          />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Σχόλια">
        <div v-if="!isLoadingPost" class="card-content">
          <table
            v-if="model.reviews.length"
            class="table is-bordered is-striped is-fullwidth"
          >
            <thead>
              <tr>
                <th>#</th>
                <th class="hidden-print">Σχόλιο</th>
                <th class="hidden-print">Προβολή</th>
              </tr>
            </thead>
            <tbody>
              <Comment
                v-for="(item, index) in model.reviews"
                :item="item"
                :index="index"
                :key="item.id"
              />
            </tbody>
          </table>

          <h4 v-else class="is-3 has-text-centered">Δε βρέθηκαν σχόλια</h4>
        </div>
      </ToggleableCard>

      <ToggleableCard title="Meta">
        <div class="card-content">
          <EditMetaDetails :meta="meta" @updateMeta="updateMeta" />
        </div>
      </ToggleableCard>

      <ToggleableCard title="Social Meta">
        <div class="card-content">
          <EditSocialMetaDetails
            :social-meta="socialMeta"
            @updateSocialMeta="updateSocialMeta"
          />
        </div>
      </ToggleableCard>

      <StickyFormFooter>
        <template v-slot:left>
          <FormSubmit :is-saving="isSaving" @reset="reset" />
        </template>
        <template v-slot:right>
          <a
            v-tooltip="'Προβολή στο e-shop'"
            :href="model.path"
            target="_blank"
          >
            {{ model.title }} <i class="fa fa-arrow-right" />
          </a>
        </template>
      </StickyFormFooter>
    </form>
  </LoadingContainer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import clone from 'lodash/clone';
import pick from 'lodash/pick';
import Multiselect from 'vue-multiselect';
import Datepicker from 'vuejs-datepicker';
import slug from 'slug';
import LoadingContainer from '@/views/components/LoadingContainer';
import EditItem from '@/views/components/EditItem';
import ImageUpload from '@/views/components/ImageUpload';
import Editor from '@/views/components/Editor';
import ToggleableCard from '@/views/components/ToggleableCard';
import EditRedirectionDetails from '@/views/components/EditRedirectionDetails';
import EditGeneralDetails from '@/views/components/EditGeneralDetails';
import EditMetaDetails from '@/views/components/EditMetaDetails';
import EditSocialMetaDetails from '@/views/components/EditSocialMetaDetails';
import EditPhotos from '@/views/components/EditPhotos';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import SymptomsSearch from '../components/SymptomsSearch';
import AuthorsSearch from '../components/AuthorsSearch';
import Comment from './components/Comment';
import ProductsSearch from '../../../Questions/views/components/ProductsSearch';

export default {
  components: {
    LoadingContainer,
    ImageUpload,
    Multiselect,
    Editor,
    Datepicker,
    ToggleableCard,
    EditRedirectionDetails,
    EditMetaDetails,
    EditSocialMetaDetails,
    EditGeneralDetails,
    EditPhotos,
    StickyFormFooter,
    SymptomsSearch,
    AuthorsSearch,
    Comment,
    ProductsSearch,
  },
  extends: EditItem,
  data() {
    return {
      redirection: {},
      meta: {},
      socialMeta: {},
      general: {},
      photos: [],
      isLoadingPhotos: false,
    };
  },
  computed: {
    ...mapGetters({
      model: 'posts/edit/getPost',
      isLoadingPost: 'posts/edit/getIsLoading',
      isLoadingPostCategories: 'postCategories/all/getIsLoading',
      isLoadingTags: 'tags/all/getIsLoading',
      postCategories: 'postCategories/all/getPostCategories',
      tags: 'tags/all/getTags',
    }),
  },
  watch: {
    model: {
      handler(newVal) {
        this.general = pick(newVal, [
          'title',
          'slug',
          'description',
          'content',
          'active',
          'author',
          'author_id',
          'post_categories',
          'tags',
          'symptoms',
          'published_at',
          'products',
        ]);

        this.redirection = clone(
          newVal.redirection || {
            new: null,
            old: null,
          },
        );
        this.meta = clone(newVal.meta);
        this.socialMeta = clone(newVal.social_meta);
        this.photos = clone(newVal.photos);
      },
      deep: true,
    },

    'general.title': {
      handler(newVal, oldVal) {
        // When title is already set and a super admin changes it
        // then we need to update slugs, urls and titles in various places
        if (oldVal !== undefined && newVal !== oldVal) {
          const newSlug = slug(newVal.toLowerCase());
          this.general.slug = newSlug;
          const schema = JSON.parse(this.meta.schema);

          schema.url = `https://carespot.gr/blog/${newSlug}`;
          schema.name = newVal;
          schema.headline = newVal;

          this.meta.schema = JSON.stringify(schema, 0, 2);
          this.meta.title = `${newVal} | Carespot.gr`;
          this.meta.keywords = newVal;
          this.socialMeta.og_url = schema.url;
          this.socialMeta.og_title = `${newVal} | Carespot.gr`;
          this.socialMeta.twitter_title = `${newVal} | Carespot.gr`;
          this.redirection.new = schema.url;

          this.photos = this.model.photos.map(photo => ({
            ...photo,
            new_path: photo.new_path.replace(this.model.slug, newSlug),
          }));
        }
      },
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getPost({ uuid: this.$route.params.uuid }),
        this.getPostCategories(),
        this.getTags(),
      ]);
    } catch (err) {
      this.$router.push('/error');
    }
  },
  methods: {
    ...mapActions({
      getPost: 'posts/edit/getPost',
      updatePost: 'posts/edit/updatePost',
      getPostPhotos: 'posts/edit/getPostPhotos',
      getPostCategories: 'postCategories/all/getPostCategories',
      getTags: 'tags/all/getTags',
    }),
    async submit() {
      try {
        const [isValid] = await Promise.all(
          this.$children.map(child => child.$validator.validateAll()),
        );

        if (!isValid) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.updatePost({
          uuid: this.model.uuid,
          post: {
            ...this.general,
            products: this.general.products.map(({ id }) => id),
            published_at: moment(this.general.published_at).format(
              'DD-MM-YYYY',
            ),
            postCategories: this.general.post_categories.map(({ id }) => id),
            tags: this.general.tags.map(({ id }) => id),
            symptoms: this.general.symptoms.map(({ id }) => id),
            redirection: this.redirection,
            meta: this.meta,
            socialMeta: this.socialMeta,
          },
        });
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'To άρθρο αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
    reset() {
      this.general = pick(this.model, [
        'title',
        'slug',
        'description',
        'content',
        'active',
        'author',
        'author_id',
        'post_categories',
        'tags',
        'symptoms',
        'published_at',
      ]);

      this.redirection = clone(
        this.model.redirection || {
          new: null,
          old: null,
        },
      );
      this.meta = clone(this.model.meta);
      this.socialMeta = clone(this.model.social_meta);
      this.photos = clone(this.model.photos);

      this.$children.forEach(child => child.$validator.reset());
    },
    updateRedirection(redirection) {
      this.redirection = redirection;
    },
    updateMeta(meta) {
      this.meta = meta;
    },
    updateSocialMeta(socialMeta) {
      this.socialMeta = socialMeta;
    },
    updateGeneral(general) {
      this.general = general;
    },
    async updatePhotos() {
      try {
        this.isLoadingPhotos = true;
        await this.getPostPhotos({ uuid: this.$route.params.uuid });
        this.isLoadingPhotos = false;
      } catch (err) {
        this.isLoadingPhotos = false;
      }
    },
    selectSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: [...this.general.symptoms, symptom],
      };
    },
    removeSymptom(symptom) {
      this.general = {
        ...this.general,
        symptoms: this.general.symptoms.filter(({ id }) => id !== symptom.id),
      };
    },
    selectAuthor(author) {
      this.general = {
        ...this.general,
        author,
        author_id: author.id,
      };
    },
    removeAuthor() {
      this.general = {
        ...this.general,
        author: null,
        author_id: null,
      };
    },

    selectProduct(product) {
      this.general = {
        ...this.general,
        products: [...(this.general.products || []), product],
      };
    },

    removeProduct(product) {
      this.general = {
        ...this.general,
        products: this.general.products.filter(({ id }) => id !== product.id),
      };
    },
  },
};
</script>
