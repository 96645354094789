<template>
  <div>
    <div v-if="isLoading" class="card-content has-text-centered">
      <VueLoading
        :size="{ width: '60px', height: '60px' }"
        type="spin"
        color="#22A684"
      />
    </div>
    <div v-else class="has-sticky-submit">
      <div class="columns is-multiline">
        <div class="column is-three-quarters">
          <div class="card">
            <div class="card-header">
              <div class="card-header-title">
                <div class="title is-6 is-marginless">
                  Προσθήκη Παραγγελίας *
                </div>
              </div>
              <div class="card-header-icon">
                <div class="field">
                  <div class="control">
                    <RouterLink :to="{ name: 'orders.list' }" class="button">
                      <span class="icon is-small">
                        <i class="fa fa-list-ul" />
                      </span>
                      <span>Λίστα</span>
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-content">
              <div v-if="hasItems">
                <div class="table-wrapper">
                  <table class="table is-bordered is-striped is-fullwidth">
                    <thead>
                      <th>#</th>
                      <th>Φωτό</th>
                      <th>Προιόν</th>
                      <th class="table__barcode">Color/Size</th>
                      <th>Stock</th>
                      <th class="hidden-print">Εμφανές</th>
                      <th>Π.Λ. Τιμή (&euro;)</th>
                      <th>Έκπτωση (%)</th>
                      <th>Τελική Τιμή (&euro;)</th>
                      <th>Φ.Π.Α. (%)</th>
                      <th class="table__count">Ποσότητα</th>
                      <th>Σύνολο (&euro;)</th>
                      <th class="hidden-print">
                        Ενέργειες
                      </th>
                    </thead>
                    <tbody>
                      <Product
                        v-for="(product, index) in newOrder.products"
                        :key="index"
                        :product="product"
                        :order-cart-ids="orderCartIds"
                        :index="index"
                        :is-editing="true"
                        @on-change-variation="changeVariation"
                        @on-change-count="changeCount"
                        @on-delete-item="deleteItem"
                      />
                    </tbody>
                  </table>
                </div>

                <ProductsSearch
                  :order="newOrder"
                  @selectProduct="selectProduct"
                />
                <div v-show="errors.has('product')" class="help is-danger">
                  Επιλέξτε τουλάχιστον 1 προιόν
                </div>

                <Coupon :has-coupon="hasCoupon" />

                <Logistics
                  :order="newOrder"
                  :has-loyalty="hasLoyalty"
                  :loyalty="loyalty"
                />
              </div>
              <div v-else class="has-text-centered">
                <i class="fa fa-shopping-basket" />
                <h4 class="title is-4">
                  Το καλάθι είναι άδειο
                </h4>

                <ProductsSearch
                  :order="newOrder"
                  @selectProduct="selectProduct"
                />
              </div>
            </div>
          </div>

          <CustomerNotes v-if="!!customerEmail" :order="newOrder" />

          <OrderAddress
            v-if="!!customerEmail"
            :order="newOrder"
            :vat-offices="vatOffices"
          />

          <OrderSendAddress v-if="!!customerEmail" :order="newOrder" />

          <OrderDetails v-if="!!customerEmail" :order="newOrder" />

          <StickyFormFooter>
            <template v-slot:left>
              <div class="field is-grouped">
                <div class="control">
                  <button
                    :class="{ 'is-loading': isSaving }"
                    :disabled="isSaving"
                    class="button is-primary"
                    type="button"
                    @click="submit"
                  >
                    <span class="icon"><i class="fa fa-save"/></span>
                    <span>Αποθήκευση</span>
                  </button>
                </div>
                <div class="control">
                  <button class="button" type="button" @click="reset">
                    <span class="icon"><i class="fa fa-refresh"/></span>
                    <span>Επαναφορά</span>
                  </button>
                </div>
              </div>
            </template>
          </StickyFormFooter>
        </div>
        <div class="column is-one-quarter">
          <CustomerSelection :order="newOrder" />

          <CustomerInfo
            v-if="!!customerEmail"
            :customer="newOrder.customer"
            :customer-type="newOrder.customer_type"
          />

          <GiftWishes :order="newOrder" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { pick, omit, isEmpty } from 'lodash';
import FormSubmit from '@/views/components/FormSubmit';
import CustomerInfo from '@/views/components/CustomerInfo';
import Notification from '@/views/components/Notification';
import StickyFormFooter from '@/views/components/StickyFormFooter';
import {
  calculateSendCharges,
  calculateProductsSum,
} from '@/utils/calculations';
import request from '@/utils/request';
import Logistics from '../../components/Logistics';
import Product from '../../components/Product';
import ProductsSearch from '../../components/ProductsSearch';
import Coupon from './components/Coupon';
import CustomerSelection from './components/CustomerSelection';
import CustomerNotes from './components/CustomerNotes';
import OrderDetails from './components/OrderDetails';
import OrderAddress from './components/OrderAddress';
import OrderSendAddress from './components/OrderSendAddress';
import GiftWishes from './components/GiftWishes';

export default {
  components: {
    Notification,
    FormSubmit,
    Logistics,
    Product,
    Coupon,
    ProductsSearch,
    CustomerSelection,
    CustomerInfo,
    CustomerNotes,
    OrderDetails,
    OrderAddress,
    OrderSendAddress,
    GiftWishes,
    StickyFormFooter,
  },

  data() {
    return {
      isSaving: false,
      vatOffices: [],
    };
  },

  computed: {
    ...mapGetters({
      isLoading: 'getIsLoading',
      newOrder: 'getNewOrder',
      sendCharges: 'getSendCharges',
      customerEmail: 'getCustomerEmail',
      itemsCount: 'getItemsCount',
      itemsTotalPrice: 'getItemsTotalPrice',
      isPharmacy: 'getIsPharmacy',
    }),

    hasItems() {
      return this.newOrder.products && this.newOrder.products.length > 0;
    },

    orderCartIds() {
      if (this.hasItems) {
        /* eslint-disable camelcase */
        return this.newOrder.products.map(({ cart_id }) => cart_id);
      }

      return [];
    },

    hasCoupon() {
      return !!this.newOrder.coupon;
    },

    loyalty() {
      return this.newOrder.customer_type === 'profile'
        ? this.newOrder.customer.profile.loyalty
        : {};
    },

    hasLoyalty() {
      return !isEmpty(this.loyalty);
    },
  },

  watch: {
    itemsCount(newVal) {
      if (this.hasCoupon) {
        // Drop coupon for 0 items
        if (newVal === 0) {
          this.resetCoupon();
        }

        // Drop coupon when min_price is less than items total price
        else if (this.newOrder.coupon.min_price > this.itemsTotalPrice) {
          this.resetCoupon();
        }
      }
    },
  },

  async created() {
    try {
      const { data } = await request.get('/vat-offices');

      this.vatOffices = data.data;
    } catch (err) {
      this.$router.push('/error');
    }
  },

  methods: {
    ...mapActions(['addNewOrder']),

    ...mapMutations(['updateNewOrder', 'resetNewOrder', 'resetCoupon']),

    async submit() {
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid || this.errors.count()) {
          throw Error('Client-side validation failed');
        }

        this.isSaving = true;
        await this.addNewOrder();
        this.isSaving = false;

        this.$notify({
          type: 'success',
          title: 'Επιτυχία',
          text: 'Η παραγγελία αποθηκεύτηκε',
        });
      } catch (err) {
        this.isSaving = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },

    reset() {
      this.$validator.reset();

      this.resetNewOrder();
    },

    /* eslint-disable camelcase */
    changeCount({ count, product }) {
      const products = this.newOrder.products.map(item =>
        item.cart_id === product.cart_id
          ? {
              ...item,
              pivot: {
                ...item.pivot,
                count,
              },
            }
          : item,
      );

      this.updateNewOrder({
        products,
      });

      this.updateSendCharges();
    },

    changeVariation({ variation, product }) {
      const newVals = omit(variation, [
        'brand',
        'code',
        'color_title',
        'id',
        'photo',
        'size_title',
      ]);

      const newPivot = pick(variation, [
        'cart_id',
        'code',
        'color_title',
        'discount',
        'final_price',
        'sell_price',
        'size_title',
      ]);

      const products = this.newOrder.products.map(item =>
        item.cart_id === product.cart_id
          ? {
              ...item,
              ...newVals,
              pivot: {
                ...item.pivot,
                ...newPivot,
                new_path: variation.photo
                  ? variation.photo.new_path
                  : item.photos[0].new_path,
              },
            }
          : item,
      );

      this.updateNewOrder({
        products,
      });

      this.updateSendCharges();
    },

    deleteItem(product) {
      const products = this.newOrder.products.filter(
        item => item.cart_id !== product.cart_id,
      );

      this.updateNewOrder({
        products,
      });

      this.updateSendCharges();
    },

    selectProduct(product) {
      const products = [
        ...this.newOrder.products,
        {
          ...product,
          pivot: {
            code: null,
            color_title: null,
            count: 1,
            discount: product.discount,
            final_price: product.final_price,
            sell_price: product.sell_price,
            size_title: null,
            new_path: product.photos[0].new_path,
            points_multiplier: product.points_multiplier,
          },
        },
      ];

      this.updateNewOrder({
        products,
      });

      this.updateSendCharges();
    },

    updateSendCharges() {
      if (!isEmpty(this.newOrder.charge_address)) {
        let country;
        if (isEmpty(this.newOrder.send_address)) {
          ({ country } = this.newOrder.charge_address);
        } else {
          ({ country } = this.newOrder.send_address);
        }

        if (country) {
          country = this.$options.filters.capitalizeGreek(country);

          const isCyprus = country === 'ΚΥΠΡΟΣ';

          let send_charge;

          if (this.isPharmacy) {
            send_charge = 0;
          } else if (this.isBoxnow) {
            const { sum } = calculateProductsSum(this.newOrder.products);
            send_charge = sum >= 39 ? 0 : 2.3;
          } else {
            const { sum, weight } = calculateProductsSum(
              this.newOrder.products,
            );
            send_charge = calculateSendCharges(
              sum,
              weight,
              isCyprus ? this.sendCharges.cy : this.sendCharges.gr,
            );
          }

          this.updateNewOrder({
            send_charge,
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-x: auto;
}

.table {
  margin: 0;

  &__barcode {
    min-width: 170px;
  }

  &__count {
    max-width: 100px;
  }
}

.fa {
  &.fa-shopping-basket {
    font-size: 40px;
    margin-bottom: 1rem;
  }
}
</style>
