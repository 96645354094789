<template>
  <tr>
    <td class="has-text-centered">{{ index }}</td>
    <td class="hidden-print has-text-centered">
      <div class="photo">
        <photo :photo="{ url: item.path }" :width="150" :height="150" />
      </div>
    </td>
    <td>
      <router-link
        :to="{ name: 'content.banners.edit', params: { uuid: item.uuid } }"
      >
        {{ item.title }}
      </router-link>
    </td>
    <td class="has-text-centered">
      <ToggleButton
        :sync="true"
        :value="item.active === true"
        :color="{ checked: '#22A684', unchecked: '#ff3860' }"
        :width="30"
        :height="15"
        @change="onChange"
      />
    </td>
    <td class="has-text-centered">
      <div class="buttons is-centered">
        <router-link
          :to="{ name: 'content.banners.edit', params: { uuid: item.uuid } }"
          class="button is-small"
        >
          <span class="icon is-small">
            <span class="fa fa-fw fa-pencil" />
          </span>
        </router-link>
        <button class="button is-small" @click="onDelete">
          <span class="icon is-small">
            <span class="fa fa-fw fa-trash-o" />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import PaginatedListItem from '@/views/components/PaginatedList/components/PaginatedListItem';
import Photo from '@/views/components/Photo';

export default {
  components: {
    Photo,
  },
  extends: PaginatedListItem,
};
</script>

<style scoped lang="scss">
tr {
  &:hover {
    cursor: move;
  }
}
</style>
